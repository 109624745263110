export const ColorNames = {
  AbsoluteZero: '#0048ba',
  AcidGreen: '#b0bf1a',
  Aero: '#7cb9e8',
  AfricanViolet: '#b284be',
  AirSuperiorityBlue: '#72a0c1',
  AliceBlue: '#f0f8ff',
  Alizarin: '#db2d43',
  AlloyOrange: '#c46210',
  Almond: '#eed9c4',
  AmaranthDeepPurple: '#9f2b68',
  AmaranthPink: '#f19cbb',
  AmaranthPurple: '#ab274f',
  Amazon: '#3b7a57',
  Amber: '#ffbf00',
  Amethyst: '#9966cc',
  AndroidGreen: '#3ddc84',
  AntiqueBrass: '#c88a65',
  AntiqueBronze: '#665d1e',
  AntiqueFuchsia: '#915c83',
  AntiqueRuby: '#841b2d',
  AntiqueWhite: '#faebd7',
  Apricot: '#fbceb1',
  Aqua: '#00ffff',
  Aquamarine: '#7fffd4',
  ArcticLime: '#d0ff14',
  ArtichokeGreen: '#4b6f44',
  ArylideYellow: '#e9d66b',
  AshGray: '#b2beb5',
  AtomicTangerine: '#ff9966',
  Aureolin: '#fdee00',
  Azure: '#f0ffff',
  AzureWebColor: '#f0ffff',
  BabyBlue: '#89cff0',
  BabyBlueEyes: '#a1caf1',
  BabyPink: '#f4c2c2',
  BabyPowder: '#fefefa',
  BakerMillerPink: '#ff91af',
  BananaMania: '#fae7b5',
  BarbiePink: '#da1884',
  BarnRed: '#7c0a02',
  BattleshipGrey: '#848482',
  BeauBlue: '#bcd4e6',
  Beaver: '#9f8170',
  Beige: '#f5f5dc',
  BdazzledBlue: '#2e5894',
  BigDipOruby: '#9c2542',
  Bisque: '#ffe4c4',
  Bistre: '#3d2b1f',
  BisqueBrown: '#967117',
  BitterLemon: '#cae00d',
  Black: '#000000',
  BlackBean: '#3d0c02',
  BlackCoral: '#54626f',
  BlackOlive: '#3b3c36',
  BlackShadows: '#bfafb2',
  BlanchedAlmond: '#ffebcd',
  BlastOffBronze: '#a57164',
  BleuDeFranze: '#318ce7',
  BlizzardBlue: '#ace5ee',
  BloodRed: '#660000',
  Blue: '#0000ff',
  BlueBell: '#a2a2d0',
  BlueCrayola: '#1f75fe',
  BlueGrayCrayola: '#6699cc',
  BlueJeans: '#5dadec',
  BlueMunsell: '#0093af',
  BlueNCS: '#0087bd',
  BluePantone: '#0018a8',
  BluePigment: '#333399',
  BlueSapphire: '#126180',
  BlueViolet: '#8a2be2',
  BlueYonder: '#5072a7',
  Bluetiful: '#3c69e7',
  Blush: '#de5d83',
  Bole: '#79443b',
  Bone: '#e3dac9',
  BrickRed: '#cb4154',
  BrightLilac: '#d891ef',
  BrightYellowCrayola: '#ffaa1d',
  BritishRacingGreen: '#004225',
  Bronze: '#cd7f32',
  Brown: '#a52a2a',
  BrownSugar: '#af6e4d',
  BudGreen: '#7bb661',
  Buff: '#ccc680',
  Burgundy: '#800020',
  Burlywood: '#deb887',
  BurnishedBrown: '#a17a74',
  BurntOrange: '#cc5500',
  BurntSienna: '#e97451',
  BurntUmber: '#8a3324',
  Byzantine: '#8d33a4',
  Byzantium: '#702963',
  CadetBlue: '#5f9ea0',
  CadetGrey: '#91a3b0',
  CadmiumGreen: '#006b3c',
  CadmiumOrange: '#ed872d',
  CafeAulait: '#a67b5b',
  CafeNoir: '#4b3621',
  CambridgeBlue: '#a3c1ad',
  Camel: '#c19a6b',
  CameoPink: '#efbbcc',
  Canary: '#ffff99',
  CanaryYellow: '#ffef00',
  CandyPink: '#e4717a',
  Cardinal: '#c41e3a',
  CaribbeanGreen: '#00cc99',
  Carmine: '#960018',
  CarmineMandP: '#d70040',
  CarnationPink: '#ffa6c9',
  Carnelian: '#b31b1b',
  CarolinaBlue: '#56a0d3',
  CarrotOrange: '#ed9121',
  Catawba: '#703642',
  CedarChest: '#c95a49',
  Celadon: '#ace1af',
  Celeste: '#b2ffff',
  Cerise: '#de3163',
  Cerulean: '#007ba7',
  CeruleanBlue: '#2a52be',
  CeruleanFrost: '#6d9bc3',
  CeruleanCrayola: '#1dacd6',
  CeruleanRGB: '#0040ff',
  Champagne: '#f7e7ce',
  ChampagnePink: '#f1ddcf',
  Charcoal: '#36454f',
  CharmPink: '#e68fac',
  Chartreuse: '#7fff00',
  ChartreuseWeb: '#80ff00',
  CherryBlossomPink: '#ffb7c5',
  Chestnut: '#954535',
  ChiliRed: '#e23d28',
  ChinaPink: '#de6fa1',
  ChineseRed: '#aa381e',
  ChineseViolet: '#856088',
  ChineseYellow: '#ffb200',
  ChocolateTraditional: '#7b3f00',
  ChocolateWeb: '#d2691e',
  Cinereous: '#98817b',
  Cinnabar: '#e34234',
  CinnamonSatin: '#cd607e',
  Citrine: '#e4d00a',
  Citron: '#9fa91f',
  Claret: '#7f1734',
  Coffee: '#6f4e37',
  ColumbiaBlue: '#b9d9eb',
  CongoPink: '#F88379',
  CoolGrey: '#8C92AC',
  Copper: '#B87333',
  CopperCrayola: '#DA8A67',
  CopperPenny: '#AD6F69',
  CopperRed: '#CB6D51',
  CopperRose: '#996666',
  Coquelicot: '#FF3800',
  Coral: '#FF7F50',
  CoralPink: '#F88379',
  Cordovan: '#893F45',
  Corn: '#FBEC5D',
  CornflowerBlue: '#6495ED',
  Cornsilk: '#FFF8DC',
  CosmicCobalt: '#2E2D88',
  CosmicLatte: '#FFF8E7',
  CoyoteBrown: '#81613C',
  CottonCandy: '#FFBCD9',
  Cream: '#FFFDD0',
  Crimson: '#DC143C',
  CrimsonUA: '#9E1B32',
  CulturedPearl: '#F5F5F5',
  Cyan: '#00FFFF',
  CyanProcess: '#00B7EB',
  CyberGrape: '#58427c',
  CyberYellow: '#ffd300',
  Cyclamen: '#f56fa1',
  Dandelion: '#FED85D',
  DarkBrown: '#654321',
  DarkByzantium: '#5D3954',
  DarkCyan: '#008B8B',
  DarkElectricBlue: '#536878',
  DarkGoldenrod: '#B8860B',
  DarkGreenX11: '#006400',
  DarkJungleGreen: '#1A2421',
  DarkKhaki: '#BDB76B',
  DarkLava: '#483C32',
  DarkLiverHorses: '#543D37',
  DarkMagenta: '#8B008B',
  DarkOliveGreen: '#556B2F',
  DarkOrange: '#FF8C00',
  DarkOrchid: '#9932CC',
  DarkPurple: '#301934',
  DarkRed: '#8B0000',
  DarkSalmon: '#E9967A',
  DarkSeaGreen: '#8FBC8F',
  DarkSienna: '#3C1414',
  DarkSkyBlue: '#8CBED6',
  DarkSlateBlue: '#483D8B',
  DarkSlateGray: '#2F4F4F',
  DarkSpringGreen: '#177245',
  DarkTurquoise: '#00CED1',
  DarkViolet: '#9400D3',
  DavysGrey: '#555555',
  DeepCerise: '#DA3287',
  DeepChampagne: '#FAD6A5',
  DeepChestnut: '#B94E48',
  DeepJungleGreen: '#004B49',
  DeepPink: '#FF1493',
  DeepSaffron: '#FF9933',
  DeepSkyBlue: '#00BFFF',
  DeepSpaceSparkle: '#4A646C',
  DeepTaupe: '#7E5E60',
  Denim: '#1560BD',
  DenimBlue: '#2243B6',
  Desert: '#C19A6B',
  DesertSand: '#EDC9AF',
  DimGray: '#696969',
  DodgerBlue: '#1E90FF',
  DrabDarkBrown: '#4A412A',
  DukeBlue: '#00009C',
  DutchWhite: '#EFDFBB',
  Ebony: '#555D50',
  Ecru: '#C2B280',
  EerieBlack: '#1B1B1B',
  Eggplant: '#614051',
  Eggshell: '#F0EAD6',
  ElectricLime: '#CCFF00',
  ElectricPurple: '#BF00FF',
  ElectricViolet: '#8F00FF',
  Emerald: '#50C878',
  Eminence: '#6C3082',
  EnglishLavender: '#B48395',
  EnglishRed: '#AB4B52',
  EnglishVermillion: '#CC474B',
  EnglishViolet: '#563C5C',
  Erin: '#00FF40',
  EtonBlue: '#96C8A2',
  Fallow: '#C19A6B',
  FaluRed: '#801818',
  Fandango: '#B53389',
  FandangoPink: '#DE5285',
  Fawn: '#E5AA70',
  FernGreen: '#4F7942',
  FieldDrab: '#6C541E',
  FieryRose: '#FF5470',
  Finn: '#683068',
  Firebrick: '#B22222',
  FireEngineRed: '#CE2029',
  Flame: '#E25822',
  Flax: '#EEDC82',
  Flirt: '#A2006D',
  FloralWhite: '#FFFAF0',
  ForestGreenWeb: '#228B22',
  FrenchBeige: '#A67B5B',
  FrenchBistre: '#856D4D',
  FrenchBlue: '#0072BB',
  FrenchFuchsia: '#FD3F92',
  FrenchLilac: '#86608E',
  FrenchLime: '#9EFD38',
  FrenchMauve: '#D473D4',
  FrenchPink: '#FD6C9E',
  FrenchRaspberry: '#C72C48',
  FrenchSkyBlue: '#77B5FE',
  FrenchViolet: '#8806CE',
  Frostbite: '#E936A7',
  Fuchsia: '#FF00FF',
  FuchsiaCrayola: '#C154C1',
  Fulvous: '#E48400',
  FuzzyWuzzy: '#87421F',
  Gainsboro: '#DCDCDC',
  Gamboge: '#E49B0F',
  GenericViridian: '#007F66',
  GhostWhite: '#F8F8FF',
  Glaucous: '#6082B6',
  GlossyGrape: '#AB92B3',
  GOGreen: '#00AB66',
  GoldCrayola: '#E6BE8A',
  GoldMetallic: '#D4AF37',
  GoldWebGolden: '#FFD700',
  GoldFusion: '#85754E',
  GoldenBrown: '#996515',
  GoldenPoppy: '#FCC200',
  GoldenYellow: '#FFDF00',
  Goldenrod: '#DAA520',
  GothamGreen: '#00573F',
  GraniteGray: '#676767',
  GrannySmithApple: '#A8E4A0',
  GrayWeb: '#808080',
  GrayX11Gray: '#BEBEBE',
  Green: '#00FF00',
  GreenCrayola: '#1CAC78',
  GreenMunsell: '#00A877',
  GreenNCS: '#009F6B',
  GreenPantone: '#00AD43',
  GreenPigment: '#00A550',
  GreenWeb: '#008000',
  GreenLizard: '#A7F432',
  GreenSheen: '#6EAEA1',
  GreenBlue: '#1164B4',
  Gunmetal: '#2A3439',
  HansaYellow: '#E9D66B',
  Harlequin: '#3FFF00',
  HarvestGold: '#DA9100',
  HeatWave: '#FF7A00',
  Heliotrope: '#DF73FF',
  HeliotropeGray: '#AA98A9',
  HollywoodCerise: '#F400A1',
  HonoluluBlue: '#006DB0',
  HookersGreen: '#49796B',
  HotMagenta: '#FF1DCE',
  HotPink: '#FF69B4',
  HunterGreen: '#355E3B',
  Iceberg: '#71A6D2',
  IlluminatingEmerald: '#319177',
  ImperialRed: '#ED2939',
  Inchworm: '#B2EC5D',
  Independence: '#4C516D',
  IndiaGreen: '#138808',
  IndianRed: '#CD5C5C',
  IndianYellow: '#E3A857',
  Indigo: '#4B0082',
  IndigoDye: '#00416A',
  InternationalKleinBlue: '#130A8F',
  InternationalOrangeEngineering: '#BA160C',
  InternationalOrangeGoldenGateBridge: '#C0362C',
  Irresistible: '#B3446C',
  Isabelline: '#F4F0EC',
  ItalianSkyBlue: '#B2FFFF',
  Ivory: '#FFFFF0',
  JapaneseCarmine: '#9D2933',
  JapaneseViolet: '#5B3256',
  Jasmine: '#F8DE7E',
  JazzberryJam: '#A50B5E',
  Jet: '#343434',
  Jonquil: '#F4CA16',
  JuneBud: '#BDDA57',
  JungleGreen: '#29AB87',
  KellyGreen: '#4CBB17',
  Keppel: '#3AB09E',
  KeyLime: '#E8F48C',
  KhakiWeb: '#C3B091',
  KhakiX11LightKhaki: '#F0E68C',
  Kobe: '#882D17',
  Kobi: '#E79FC4',
  Kobicha: '#6B4423',
  KSUPurple: '#512888',
  LanguidLavender: '#D6CADD',
  LapisLazuli: '#26619C',
  LaserLemon: '#FFFF66',
  LaurelGreen: '#A9BA9D',
  Lava: '#CF1020',
  LavenderFloral: '#B57EDC',
  LavenderWeb: '#E6E6FA',
  LavenderBlue: '#CCCCFF',
  LavenderBlush: '#FFF0F5',
  LavenderGray: '#C4C3D0',
  LawnGreen: '#7CFC00',
  Lemon: '#FFF700',
  LemonChiffon: '#FFFACD',
  LemonCurry: '#CCA01D',
  LemonGlacier: '#FDFF00',
  LemonMeringue: '#F6EABE',
  LemonYellow: '#FFF44F',
  LemonYellowCrayola: '#FFFF9F',
  Liberty: '#545AA7',
  LightBlue: '#ADD8E6',
  LightCoral: '#F08080',
  LightCornflowerBlue: '#93CCEA',
  LightCyan: '#E0FFFF',
  LightFrenchBeige: '#C8AD7F',
  LightGoldenrodYellow: '#FAFAD2',
  LightGray: '#D3D3D3',
  LightGreen: '#90EE90',
  LightOrange: '#FED8B1',
  LightPeriwinkle: '#C5CBE1',
  LightPink: '#FFB6C1',
  LightSalmon: '#FFA07A',
  LightSeaGreen: '#20B2AA',
  LightSkyBlue: '#87CEFA',
  LightSlateGray: '#778899',
  LightSteelBlue: '#B0C4DE',
  LightYellow: '#FFFFE0',
  Lilac: '#C8A2C8',
  LilacLuster: '#AE98AA',
  LimeColorWheel: '#BFFF00',
  LimeWebX11Green: '#00FF00',
  LimeGreen: '#32CD32',
  LincolnGreen: '#195905',
  Linen: '#FAF0E6',
  Lion: '#DECC9C',
  LiseranPurple: '#DE6FA1',
  LittleBoyBlue: '#6CA0DC',
  Liver: '#674C47',
  LiverDogs: '#B86D29',
  LiverOrgan: '#6C2E1F',
  LiverChestnut: '#987456',
  Livid: '#6699CC',
  MacaroniAndCheese: '#FFBD88',
  MadderLake: '#CC3336',
  Magenta: '#FF00FF',
  MagentaCrayola: '#F653A6',
  MagentaDye: '#CA1F7B',
  MagentaPantone: '#D0417E',
  MagentaProcess: '#FF0090',
  MagentaHaze: '#9F4576',
  MagicMint: '#AAF0D1',
  Magnolia: '#F2E8D7',
  Mahogany: '#C04000',
  Maize: '#FBEC5D',
  MaizeCrayola: '#F2C649',
  MajorelleBlue: '#6050DC',
  Malachite: '#0BDA51',
  Manatee: '#979AAA',
  Mandarin: '#F37A48',
  Mango: '#FDBE02',
  MangoTango: '#FF8243',
  Mantis: '#74C365',
  MardiGras: '#880085',
  Marigold: '#EAA221',
  MaroonCrayola: '#C32148',
  MaroonWeb: '#800000',
  MaroonX11: '#B03060',
  Mauve: '#E0B0FF',
  MauveTaupe: '#915F6D',
  Mauvelous: '#EF98AA',
  MaximumBlue: '#47ABCC',
  MaximumBlueGreen: '#30BFBF',
  MaximumBluePurple: '#ACACE6',
  MaximumGreen: '#5E8C31',
  MaximumGreenYellow: '#D9E650',
  MaximumPurple: '#733380',
  MaximumRed: '#D92121',
  MaximumRedPurple: '#A63A79',
  MaximumYellow: '#FAFA37',
  MaximumYellowRed: '#F2BA49',
  MayGreen: '#4C9141',
  MayaBlue: '#73C2FB',
  MediumAquamarine: '#66DDAA',
  MediumBlue: '#0000CD',
  MediumCandyAppleRed: '#E2062C',
  MediumCarmine: '#AF4035',
  MediumChampagne: '#F3E5AB',
  MediumOrchid: '#BA55D3',
  MediumPurple: '#9370DB',
  MediumSeaGreen: '#3CB371',
  MediumSlateBlue: '#7B68EE',
  MediumSpringGreen: '#00FA9A',
  MediumTurquoise: '#48D1CC',
  MediumVioletRed: '#C71585',
  MellowApricot: '#F8B878',
  MellowYellow: '#F8DE7E',
  Melon: '#FEBAAD',
  MetallicGold: '#D3AF37',
  MetallicSeaweed: '#0A7E8C',
  MetallicSunburst: '#9C7C38',
  MexicanPink: '#E4007C',
  MiddleBlue: '#7ED4E6',
  MiddleBlueGreen: '#8DD9CC',
  MiddleBluePurple: '#8B72BE',
  MiddleGreen: '#4D8C57',
  MiddleGreenYellow: '#ACBF60',
  MiddleGrey: '#8B8680',
  MiddlePurple: '#D982B5',
  MiddleRed: '#E58E73',
  MiddleRedPurple: '#A55353',
  MiddleYellow: '#FFEB00',
  MiddleYellowRed: '#ECB176',
  Midnight: '#702670',
  MidnightBlue: '#191970',
  MidnightGreenEagleGreen: '#004953',
  MikadoYellow: '#FFC40C',
  MimiPink: '#FFDAE9',
  Mindaro: '#E3F988',
  Ming: '#36747D',
  MinionYellow: '#F5E050',
  Mint: '#3EB489',
  MintCream: '#F5FFFA',
  MintGreen: '#98FF98',
  MistyMoss: '#BBB477',
  MistyRose: '#FFE4E1',
  ModeBeige: '#967117',
  MonaLisa: '#FF948E',
  MorningBlue: '#8DA399',
  MossGreen: '#8A9A5B',
  MountainMeadow: '#30BA8F',
  MountbattenPink: '#997A8D',
  MSUGreen: '#18453B',
  Mulberry: '#C54B8C',
  MulberryCrayola: '#C8509B',
  Mustard: '#FFDB58',
  MyrtleGreen: '#317873',
  Mystic: '#D65282',
  MysticMaroon: '#AD4379',
  NadeshikoPink: '#F6ADC6',
  NaplesYellow: '#FADA5E',
  NavyBlue: '#000080',
  NavyBlueCrayola: '#1974D2',
  NeonBlue: '#4666FF',
  NeonGreen: '#39FF14',
  NeonFuchsia: '#FE4164',
  NewCar: '#214FC6',
  NewYorkPink: '#D7837F',
  Nickel: '#727472',
  NonPhotoBlue: '#A4DDED',
  Nyanza: '#E9FFDB',
  Ochre: '#CC7722',
  OldBurgundy: '#43302E',
  OldGold: '#CFB53B',
  OldLace: '#FDF5E6',
  OldLavender: '#796878',
  OldMauve: '#673147',
  OldRose: '#C08081',
  OldSilver: '#848482',
  Olive: '#808000',
  OliveDrab3: '#6B8E23',
  OliveDrab7: '#3C341F',
  OliveGreen: '#B5B35C',
  Olivine: '#9AB973',
  Onyx: '#353839',
  Opal: '#A8C3BC',
  OperaMauve: '#B784A7',
  Orange: '#FF7F00',
  OrangeCrayola: '#FF7538',
  OrangePantone: '#FF5800',
  OrangeWeb: '#FFA500',
  OrangePeel: '#FF9F00',
  OrangeRed: '#FF681F',
  OrangeRedCrayola: '#FF5349',
  OrangeSoda: '#FA5B3D',
  OrangeYellow: '#F5BD1F',
  OrangeYellowCrayola: '#F8D568',
  Orchid: '#DA70D6',
  OrchidPink: '#F2BDCD',
  OrchidCrayola: '#E29CD2',
  OuterSpaceCrayola: '#2D383A',
  OutrageousOrange: '#FF6E4A',
  Oxblood: '#4A0000',
  OxfordBlue: '#002147',
  OUCrimsonRed: '#841617',
  PacificBlue: '#1CA9C9',
  PakistanGreen: '#006600',
  PalatinatePurple: '#682860',
  PaleAqua: '#BED3E5',
  PaleCerulean: '#9BC4E2',
  PaleDogwood: '#ED7A9B',
  PalePink: '#FADADD',
  PalePurplePantone: '#FAE6FA',
  PaleSpringBud: '#ECEBBD',
  PansyPurple: '#78184A',
  PaoloVeroneseGreen: '#009B7D',
  PapayaWhip: '#FFEFD5',
  ParadisePink: '#E63E62',
  Parchment: '#F1E9D2',
  ParisGreen: '#50C878',
  PastelPink: '#DEA5A4',
  Patriarch: '#800080',
  Paua: '#1F005E',
  PaynesGrey: '#536878',
  Peach: '#FFE5B4',
  PeachCrayola: '#FFCBA4',
  PeachPuff: '#FFDAB9',
  Pear: '#D1E231',
  PearlyPurple: '#B768A2',
  Periwinkle: '#CCCCFF',
  PeriwinkleCrayola: '#C3CDE6',
  PermanentGeraniumLake: '#E12C2C',
  PersianBlue: '#1C39BB',
  PersianGreen: '#00A693',
  PersianIndigo: '#32127A',
  PersianOrange: '#D99058',
  PersianPink: '#F77FBE',
  PersianPlum: '#701C1C',
  PersianRed: '#CC3333',
  PersianRose: '#FE28A2',
  Persimmon: '#EC5800',
  PewterBlue: '#8BA8B7',
  Phlox: '#DF00FF',
  PhthaloBlue: '#000F89',
  PhthaloGreen: '#123524',
  PicoteeBlue: '#2E2787',
  PictorialCarmine: '#C30B4E',
  PiggyPink: '#FDDDE6',
  PineGreen: '#01796F',
  PineGreen2: '#2A2F23',
  Pink: '#FFC0CB',
  PinkPantone: '#D74894',
  PinkLace: '#FFDDF4',
  PinkLavender: '#D8B2D1',
  PinkSherbet: '#F78FA7',
  Pistachio: '#93C572',
  Platinum: '#E5E4E2',
  Plum: '#8E4585',
  PlumWeb: '#DDA0DD',
  PlumpPurple: '#5946B2',
  PolishedPine: '#5DA493',
  PompAndPower: '#86608E',
  Popstar: '#BE4F62',
  PortlandOrange: '#FF5A36',
  PowderBlue: '#B0E0E6',
  PrairieGold: '#E1CA7A',
  PrincetonOrange: '#F58025',
  ProcessYellow: '#FFEF00',
  Prune: '#701C1C',
  PrussianBlue: '#003153',
  PsychedelicPurple: '#DF00FF',
  Puce: '#CC8899',
  PullmanBrownUPS: '#644117',
  Pumpkin: '#FF7518',
  Purple: '#6A0DAD',
  PurpleWeb: '#800080',
  PurpleMunsell: '#9F00C5',
  PurpleX11: '#A020F0',
  PurpleMountainMajesty: '#9678B6',
  PurpleNavy: '#4E5180',
  PurplePizzazz: '#FE4EDA',
  PurplePlum: '#9C51B6',
  Purpureus: '#9A4EAE',
  QueenBlue: '#436B95',
  QueenPink: '#E8CCD7',
  QuickSilver: '#A6A6A6',
  QuinacridoneMagenta: '#8E3A59',
  RadicalRed: '#FF355E',
  RaisinBlack: '#242124',
  Rajah: '#FBAB60',
  Raspberry: '#E30B5D',
  RaspberryGlace: '#915F6D',
  RaspberryRose: '#B3446C',
  RawSienna: '#D68A59',
  RawUmber: '#826644',
  RazzleDazzleRose: '#FF33CC',
  Razzmatazz: '#E3256B',
  RazzmicBerry: '#8D4E85',
  RebeccaPurple: '#663399',
  Red: '#FF0000',
  RedCrayola: '#EE204D',
  RedMunsell: '#F2003C',
  RedNCS: '#C40233',
  RedPantone: '#ED2939',
  RedPigment: '#ED1C24',
  RedRYB: '#FE2712',
  RedOrange: '#FF5349',
  RedOrangeCrayola: '#FF681F',
  RedOrangeColorWheel: '#FF4500',
  RedPurple: '#E40078',
  RedSalsa: '#FD3A4A',
  RedViolet: '#C71585',
  RedVioletCrayola: '#C0448F',
  RedVioletColorWheel: '#922B3E',
  Redwood: '#A45A52',
  ResolutionBlue: '#002387',
  Rhythm: '#777696',
  RichBlack: '#004040',
  RichBlackFOGRA29: '#010B13',
  RichBlackFOGRA39: '#010203',
  RifleGreen: '#444C38',
  RobinEggBlue: '#00CCCC',
  RocketMetallic: '#8A7F80',
  RojoSpanishRed: '#A91101',
  RomanSilver: '#838996',
  Rose: '#FF007F',
  RoseBonbon: '#F9429E',
  RoseDust: '#9E5E6F',
  RoseEbony: '#674846',
  RoseMadder: '#E32636',
  RosePink: '#FF66CC',
  RosePompadour: '#ED7A9B',
  RoseRed: '#C21E56',
  RoseTaupe: '#905D5D',
  RoseVale: '#AB4E52',
  Rosewood: '#65000B',
  RossoCorsa: '#D40000',
  RosyBrown: '#BC8F8F',
  RoyalBlueDark: '#002366',
  RoyalBlueLight: '#4169E1',
  RoyalPurple: '#7851A9',
  RoyalYellow: '#FADA5E',
  Ruber: '#CE4676',
  RubineRed: '#D10056',
  Ruby: '#E0115F',
  RubyRed: '#9B111E',
  RubyTuesday: '#D71868',
  Rufous: '#A81C07',
  Russet: '#80461B',
  RussianGreen: '#679267',
  RussianViolet: '#32174D',
  Rust: '#B7410E',
  RustyRed: '#DA2C43',
  SacramentoStateGreen: '#043927',
  SaddleBrown: '#8B4513',
  SafetyOrange: '#FF7800',
  SafetyOrangeBlaze: '#FF6700',
  SafetyYellow: '#EED202',
  Saffron: '#F4C430',
  Sage: '#BCB88A',
  StPatricksBlue: '#23297A',
  Salmon: '#FA8072',
  SalmonPink: '#FF91A4',
  Sand: '#C2B280',
  SandDune: '#967117',
  SandyBrown: '#F4A460',
  SapGreen: '#507D2A',
  Sapphire: '#0F52BA',
  SapphireBlue: '#0067A5',
  SapphireCrayola: '#2D5DA1',
  SatinSheenGold: '#CBA135',
  Scarlet: '#FF2400',
  SchaussPink: '#FF91AF',
  SchoolBusYellow: '#FFD800',
  ScreaminGreen: '#66FF66',
  SeaGreen: '#2E8B57',
  SeaGreenCrayola: '#00FFCD',
  Seance: '#612086',
  SealBrown: '#59260B',
  Seashell: '#FFF5EE',
  Secret: '#764374',
  SelectiveYellow: '#FFBA00',
  Sepia: '#704214',
  Shadow: '#8A795D',
  ShadowBlue: '#778BA5',
  ShamrockGreen: '#009E60',
  SheenGreen: '#8FD400',
  ShimmeringBlush: '#D98695',
  ShinyShamrock: '#5FA778',
  ShockingPink: '#FC0FC0',
  ShockingPinkCrayola: '#FF6FFF',
  Sienna: '#882D17',
  Silver: '#C0C0C0',
  SilverCrayola: '#C9C0BB',
  SilverMetallic: '#AAA9AD',
  SilverChalice: '#ACACAC',
  SilverPink: '#C4AEAD',
  SilverSand: '#BFC1C2',
  Sinopia: '#CB410B',
  SizzlingRed: '#FF3855',
  SizzlingSunrise: '#FFDB00',
  Skobeloff: '#007474',
  SkyBlue: '#87CEEB',
  SkyBlueCrayola: '#76D7EA',
  SkyMagenta: '#CF71AF',
  SlateBlue: '#6A5ACD',
  SlateGray: '#708090',
  SlimyGreen: '#299617',
  Smitten: '#C84186',
  SmokyBlack: '#100C08',
  Snow: '#FFFAFA',
  SolidPink: '#893843',
  SonicSilver: '#757575',
  SpaceCadet: '#1D2951',
  SpanishBistre: '#807532',
  SpanishBlue: '#0070B8',
  SpanishCarmine: '#D10047',
  SpanishGray: '#989898',
  SpanishGreen: '#009150',
  SpanishOrange: '#E86100',
  SpanishPink: '#F7BFBE',
  SpanishRed: '#E60026',
  SpanishSkyBlue: '#00FFFE',
  SpanishViolet: '#4C2882',
  SpanishViridian: '#007F5C',
  SpringBud: '#A7FC00',
  SpringFrost: '#87FF2A',
  SpringGreen: '#00FF7F',
  SpringGreenCrayola: '#ECEBBD',
  StarCommandBlue: '#007BB8',
  SteelBlue: '#4682B4',
  SteelPink: '#CC33CC',
  StilDeGrainYellow: '#FADA5E',
  Straw: '#E4D96F',
  Strawberry: '#FA5053',
  StrawberryBlonde: '#FF9361',
  StrongLimeGreen: '#33CC33',
  SugarPlum: '#914E75',
  Sunglow: '#FFCC33',
  Sunray: '#E3AB57',
  Sunset: '#FAD6A5',
  SuperPink: '#CF6BA9',
  SweetBrown: '#A83731',
  SyracuseOrange: '#D44500',
  Tan: '#D2B48C',
  TanCrayola: '#D99A6C',
  Tangerine: '#F28500',
  TangoPink: '#E4717A',
  TartOrange: '#FB4D46',
  Taupe: '#483C32',
  TaupeGray: '#8B8589',
  TeaGreen: '#D0F0C0',
  TeaRose: '#F4C2C2',
  Teal: '#008080',
  TealBlue: '#367588',
  Technobotanica: '#00FFBF',
  Telemagenta: '#CF3476',
  TenneTawny: '#CD5700',
  TerraCotta: '#E2725B',
  Thistle: '#D8BFD8',
  ThulianPink: '#DE6FA1',
  TickleMePink: '#FC89AC',
  TiffanyBlue: '#0ABAB5',
  Timberwolf: '#DBD7D2',
  TitaniumYellow: '#EEE600',
  Tomato: '#FF6347',
  Tourmaline: '#86A1A9',
  TropicalRainforest: '#00755E',
  TrueBlue: '#2D68C4',
  TrypanBlue: '#1C05B3',
  TuftsBlue: '#3E8EDE',
  Tumbleweed: '#DEAA88',
  Turquoise: '#40E0D0',
  TurquoiseBlue: '#00FFEF',
  TurquoiseGreen: '#A0D6B4',
  TurtleGreen: '#8A9A5B',
  Tuscan: '#FAD6A5',
  TuscanBrown: '#6F4E37',
  TuscanRed: '#7C4848',
  TuscanTan: '#A67B5B',
  Tuscany: '#C09999',
  TwilightLavender: '#8A496B',
  TyrianPurple: '#66023C',
  UABlue: '#0033AA',
  UARed: '#D9004C',
  Ultramarine: '#3F00FF',
  UltramarineBlue: '#4166F5',
  UltraPink: '#FF6FFF',
  UltraRed: '#FC6C85',
  Umber: '#635147',
  UnbleachedSilk: '#FFDDCA',
  UnitedNationsBlue: '#009EDB',
  UniversityOfPennsylvaniaRed: '#A50021',
  UnmellowYellow: '#FFFF66',
  UPForestGreen: '#014421',
  UPMaroon: '#7B1113',
  UpsdellRed: '#AE2029',
  UranianBlue: '#AFDBF5',
  USAFABlue: '#004F98',
  VanDykeBrown: '#664228',
  Vanilla: '#F3E5AB',
  VanillaIce: '#F38FA9',
  VegasGold: '#C5B358',
  VenetianRed: '#C80815',
  Verdigris: '#43B3AE',
  Vermilion: '#E34234',
  Vermilion2: '#D9381E',
  Veronica: '#A020F0',
  Violet: '#8F00FF',
  VioletColorWheel: '#7F00FF',
  VioletCrayola: '#963D7F',
  VioletRYB: '#8601AF',
  VioletWeb: '#EE82EE',
  VioletBlue: '#324AB2',
  VioletBlueCrayola: '#766EC8',
  VioletRed: '#F75394',
  VioletRedPerBang: '#F0599C',
  Viridian: '#40826D',
  ViridianGreen: '#009698',
  VividBurgundy: '#9F1D35',
  VividSkyBlue: '#00CCFF',
  VividTangerine: '#FFA089',
  VividViolet: '#9F00FF',
  Volt: '#CEFF00',
  WarmBlack: '#004242',
  WeezyBlue: '#189BCC',
  Wheat: '#F5DEB3',
  White: '#FFFFFF',
  WildBlueYonder: '#A2ADD0',
  WildOrchid: '#D470A2',
  WildStrawberry: '#FF43A4',
  WildWatermelon: '#FC6C85',
  WindsorTan: '#A75502',
  Wine: '#722F37',
  WineDregs: '#673147',
  WinterSky: '#FF007C',
  WintergreenDream: '#56887D',
  Wisteria: '#C9A0DC',
  WoodBrown: '#C19A6B',
  Xanadu: '#738678',
  Xanthic: '#EEED09',
  Xanthous: '#F1B42F',
  YaleBlue: '#00356B',
  Yellow: '#FFFF00',
  YellowCrayola: '#FCE883',
  YellowMunsell: '#EFCC00',
  YellowNCS: '#FFD300',
  YellowPantone: '#FEDF00',
  YellowProcess: '#FFEF00',
  YellowRYB: '#FEFE33',
  YellowGreen: '#9ACD32',
  YellowGreenCrayola: '#C5E384',
  YellowGreenColorWheel: '#30B21A',
  YellowOrange: '#FFAE42',
  YellowOrangeColorWheel: '#FF9505',
  YellowSunshine: '#FFF700',
  YInMnBlue: '#2E5090',
  Zaffre: '#0014A8',
  ZinnwalditeBrown: '#2C1608',
  Zomp: '#39A78E',

};

export default ColorNames;